<template>
    <!-- Single Blog Start -->
    <div class="single-blog">
        <div class="blog-image">
            <a href="javascript:;"><img :src="blog.blogimage" alt="course"></a>
        </div>
        <div class="blog-content">
            <div class="blog-author">
                <div class="author">
                    <div class="author-thumb">
                        <a href="javascript:;"><img :src="blog.authorImage" alt="Author"></a>
                    </div>
                    <div class="author-name">
                        <a class="name" href="javascript:;">{{ blog.authorName }}</a>
                    </div>
                </div>
                <div class="tag">
                    <a href="javascript:;">{{ blog.category }}</a>
                </div>
            </div>

            <h4 class="title"><a href="javascript:;">{{ blog.name }}</a></h4>

            <div class="blog-meta">
                <span> <i class="icofont-calendar"></i>{{ blog.date }}</span>
                <span> <i class="icofont-heart"></i> {{ blog.likes }}+</span>
            </div>

            <a href="javascript:;" class="btn btn-secondary btn-hover-primary">Read More</a>
        </div>
    </div>
    <!-- Single Blog End -->
</template>
<script>
export default{
    name:"SingleBlogPage",
    props:['blog'],
    methods:{

    }
}
</script>