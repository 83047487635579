<template>
 <!-- Blog Start -->
<div class="section section-padding mt-n1">
    <div class="container">
        <!-- Section Title Start -->
        <div v-if="this.$route.name != 'Blog'" class="section-title shape-03 text-center">
            <h5 class="sub-title">Latest News</h5>
            <h2 class="main-title">Educational Tips & <span> Tricks</span></h2>
        </div>
        <!-- Section Title End -->

        <!-- Blog Wrapper Start -->
        <div class="blog-wrapper">
            <div class="row">
                <div v-for="Blog in Blogs" :key="Blog" class="col-lg-4 col-md-6">
                    <SingleBlogCard :blog="Blog"  />
                </div>
            </div>
        </div>
        <!-- Blog Wrapper End -->

    </div>
</div>
<!-- Blog End -->
</template>
<script>
import SingleBlogCard from '@/components/SingleBlogCard.vue';
export default{
    name:"Blog",
    components:{
        SingleBlogCard
    },
    methods:{

    },
    data(){
        return{
            Blogs:[
                {name:"Carpentry Hacks - Hands On!",authorImage:"https://via.placeholder.com/500",authorName:"Ajayi",likes :"345",date:"10th May 2022",category:"Carpentry",blogimage:"https://via.placeholder.com/750"},
                {name:"Tiling the right way - Hands On!",authorImage:"https://via.placeholder.com/500",authorName:"Ajayi",likes :"345",date:"14th June 2022",category:"Masonry",blogimage:"https://via.placeholder.com/750"},
                {name:"Painting tips ",authorImage:"https://via.placeholder.com/500",authorName:"Ajayi",likes :"345",date:"4th March 2022",category:"Painting",blogimage:"https://via.placeholder.com/750"}
            ]
        }
    }
}
</script>