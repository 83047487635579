import { createApp } from 'vue'
import Vue from 'vue';
import App from './App.vue'
import router from './router'
import store from './store';
import "./assets/css/style.css";
import "./assets/css/custom.css";
import "./assets/css/vendor/plugins.css";
import "./assets/js/plugins/popper.min.js";
import "./assets/js/plugins/bootstrap.min.js";
import "./assets/css/plugins/nice-select.css";
import "./assets/js/plugins/jquery.nice-select.min.js"
// Vue.prototype.$api= 'http://127.0.0.1/api/v1'
createApp(App).use(store).use(router).mount('#app')
