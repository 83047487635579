<template>
<!-- Download App Start -->
    <div class="section section-padding download-section">

        <div class="app-shape-1"></div>
        <div class="app-shape-2"></div>
        <div class="app-shape-3"></div>
        <div class="app-shape-4"></div>

        <div class="container">

            <!-- Download App Wrapper Start -->
            <div class="download-app-wrapper mt-n6">

                <!-- Section Title Start -->
                <div class="section-title section-title-white">
                    <h5 class="sub-title">Ready to start?</h5>
                    <h2 class="main-title">Enroll for any of our courses and get empowered.</h2>
                    <!-- <p class="text-white">...get empowered.</p> -->
                </div>
                <!-- Section Title End -->

                <img class="shape-1 animation-right" src="@/assets/images/shape/shape-14.png" alt="Shape">

                <!-- Download App Button End -->
                <div class="download-app-btn">
                    <ul class="app-btn">
                        <li><router-link :to="{name:'Register'}" class="font-weight-bold btn-hover-dark">Sign Up</router-link ></li>
                        <li><router-link :to="{name:'Login'}" class="font-weight-bold btn-hover-dark">Sign In</router-link ></li>
                    </ul>
                </div>
                <!-- Download App Button End -->

            </div>
            <!-- Download App Wrapper End -->

        </div>
    </div>
    <!-- Download App End -->
</template>
<script>
export default{

}

</script>