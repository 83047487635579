<template>
  <!-- Footer Start  -->
    <div class="section footer-section">
        <!-- Footer Widget Section Start -->
        <div class="footer-widget-section">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 order-md-1 order-lg-1">
                        <!-- Footer Widget Start -->
                        <div class="footer-widget">
                            <div class="widget-logo">
                                <a href="#"><img src="@/assets/images/logo.png" alt="Logo"></a>
                            </div>

                            <div class="widget-address">
                                <h4 class="footer-widget-title"> Builders' Hub Vocational Academy</h4>
                                <p>Lagos State, Nigeria.</p>
                            </div>

                            <ul class="widget-info">
                                <li>
                                    <p> <i class="flaticon-email"></i> <a href="#">{{ email }}</a> </p>
                                </li>
                                <li>
                                    <p> <i class="flaticon-phone-call"></i> <a :href="'tel:' + phone">{{ phone }}</a> </p>
                                </li>
                            </ul>

                            <ul class="widget-social">
                                <li><a href="https://facebook.com/bhubcollege"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/bhubcollege"><i class="flaticon-twitter"></i></a></li>
                                <!-- <li><a href="https://"><i class="flaticon-skype"></i></a></li> -->
                                <li><a href="https://instagram.com/bhubcollege"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <!-- Footer Widget End -->

                    </div>
                    <div class="col-lg-6 order-md-3 order-lg-2">

                        <!-- Footer Widget Link Start -->
                        <div class="footer-widget-link">

                            <!-- Footer Widget Start -->
                            <div class="footer-widget">
                                <h4 class="footer-widget-title">Courses</h4>

                                <ul class="widget-link">
                                    <li v-for="category in categories" :key="category"  ><a href="#">{{ category.name }}</a></li>

                                </ul>

                            </div>
                            <!-- Footer Widget End -->

                            <!-- Footer Widget Start -->
                            <div class="footer-widget">
                                <h4 class="footer-widget-title">Quick Links</h4>

                                <ul class="widget-link">
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Terms & Conditions</a></li>
                                    <li><a href="#">Blog</a></li>
                                    <li><router-link to="About">About Us</router-link></li>
                                    <li><router-link to="Contact">Contact Us</router-link></li>
                                    <!-- <li><a href="#">Course FAQ’s</a></li> -->
                                </ul>

                            </div>
                            <!-- Footer Widget End -->

                        </div>
                        <!-- Footer Widget Link End -->

                    </div>
                    <div class="col-lg-3 col-md-6 order-md-2 order-lg-3">

                        <!-- Footer Widget Start -->
                        <div class="footer-widget">
                            <h4 class="footer-widget-title">Subscribe</h4>

                            <div class="widget-subscribe">
                                <p>Subscribe to our weekly newsletter for update on courses and trends in the industry</p>

                                <div class="widget-form">
                                    <form action="#">
                                        <input type="text" placeholder="Email here">
                                        <button class="btn btn-primary btn-hover-dark">Subscribe Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <!-- Footer Widget End -->

                    </div>
                </div>
            </div>
            <img class="shape-2 animation-left" src="@/assets/images/shape/shape-22.png" alt="Shape">
        </div>
        <!-- Footer Widget Section End -->

        <!-- Footer Copyright Start -->
        <div class="footer-copyright">
            <div class="container">

                <!-- Footer Copyright Start -->
                <div class="copyright-wrapper">
                    <div class="copyright-link">
                        <a href="#">Terms of Service</a>
                        <a href="#">Privacy Policy</a>
                        <a href="#">Security</a>
                    </div>
                    <div class="copyright-text">
                        <p>&copy; {{ new Date().getFullYear() }} Builders Hub Construction College</p>
                    </div>
                </div>
                <!-- Footer Copyright End -->

            </div>
        </div>
        <!-- Footer Copyright End -->

    </div>
    <!-- Footer End -->
</template>
<script>
export default{
    name:"Footer",
    data(){
        return{
            name:"Builders Hub Construction College",
            address:"",
            email:"info@bhubcollege.com",
            phone:"(+234) 09073016297",
        }
    },
    methods:{
        getcurrentYear(){
           var currentYear = new Date().getFullYear(); 
           return currentYear
        }
    },
    computed:{
        categories(){
            return this.$store.state.Categories
        }
    }
}
</script>