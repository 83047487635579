import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
   {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/partnership',
    name: 'Partnership',
    component: () => import('../views/Partnership.vue')
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../views/Events.vue')
  },
  {
    path: '/events-details/:id',
    name: 'EventDetails',
    component: () => import('../views/EventDetails.vue')
  },
  {
    path: '/events/:type',
    name: 'EventShow',
    component: () => import('../views/EventShow.vue')
  },
  {
    path: '/learn-a-skill',
    name: 'SkillRegistration',
    component: () => import('../views/SkillRegistration.vue')
  },
  {
    path: '/vacancies',
    name: 'Vacancies',
    component: () => import('../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/courses',
    name: 'Courses',
    component: () => import('../views/Courses.vue')
  },
  {
    path: '/courses/:slug',
    name: 'CourseDetail',
    props: true,
    component: () => import('../views/Users/CourseDetail.vue')
  },
  {
    path: '/my-courses',
    name: 'UserCourses',
    component: () => import('../views/Users/Courses.vue')
  },
  {
    path: '/my-courses/:slug',
    name: 'MyCourses',
    component: () => import('../views/Users/MyCourses.vue')
  },
  {
    path: '/instructor/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Instructors/Dashboard.vue')
  },
  {
    path: '/instructor/courses',
    name: 'InstructorCourses',
    component: () => import('../views/Instructors/Courses.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Users/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Users/Register.vue')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/Users/Register.vue')
  },
  {
    path: '/verification/:id',
    name: 'Verification',
    component: () => import('../views/Users/Verification.vue')
  },
  { path: '/:catchAll(.*)', redirect: '/404' },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
      return { top: 0 }
  },
})

export default router
