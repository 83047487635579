<template>
<!-- Testimonial End -->
    <div class="section section-padding-02 mt-n1">
    <div class="container">

        <!-- Section Title Start -->
        <div class="section-title shape-03 text-center">
            <h5 class="sub-title">Student Testimonial</h5>
            <h2 class="main-title">Feedback From <span> Student</span></h2>
        </div>
        <!-- Section Title End -->

        <!-- Testimonial Wrapper End -->
        <div class="testimonial-wrapper testimonial-active">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                   <singleTestmonial v-for="Testimonal in Testimonals" :key="Testimonal"  :testimony="Testimonal" />
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <!-- Testimonial Wrapper End -->

    </div>
    </div>
    <!-- Testimonial End -->


</template>
<script>
import Swiper from '@/assets/js/plugins/swiper-bundle.min.js'
import singleTestmonial from '@/components/home/singleTestimonal.vue'
import yusuf from '@/assets/images/testimonials/yusuf.jpg'
import adigun from '@/assets/images/testimonials/adigun.jpg'
import rufus from '@/assets/images/testimonials/rufus.jpg'
import junaid from '@/assets/images/testimonials/junaid.jpg'
import yusu from '@/assets/images/testimonials/yusu.jpg'

export default{
    name:"Testimonal",
    components:{
        singleTestmonial
    },
    data(){
        return{
            Testimonals:[
                {name:"Yusuf Lukman Olanrewaju",text:"It was actually  not easy  as matter of fact I thought it was not going to be possible but I decided to give it a try  the I see the possibility through the rigorous and flexibility leaning environment in Builders Hub. The experience is varying but majorly on solving plumbing related problems. ",position:"Plumber",authorImage:yusu,rating:"4.5"},
                {name:"Yusuf",text:"Builder's Hub bocational academy environment is cool, quiet and convenient for learning. This training really helped me alots, it contribute to my career life, through this i can boldly say i can wire a three bedroom flat, do the load analysis of the building which I'm unable to do before.",position:"Electircian",authorImage:yusuf,rating:"3.0"},
                {name:"Sulaimon Fauzziy Taiwo",text:"It's amazing. It as being a great experience so far, I didn't know anything about electrical before coming here, but the training has help me a lot, I will be leaving with my head high.",position:"Electrician",authorImage:adigun,rating:"3.0"},
                {name:"Imangbe Rufus",text:"The learning environment is serine and with up to date learning facilities that really help my quick understanding of tiling. I learnt a whole lots from identification of different tiles and their uses, measurements, tile layout, costing and the practicals which really exposed me to morden methods of laying tiles ",position:"Tiler",authorImage:rufus,rating:"3.0"},
                {name:"Junaid",text:"It's interesting, interactive, educative and training is well delivered. My experience so far is excellent and I have actually learnt a lot in plumbing fittings and fixtures, installation, general piping work, joining of pipes as I was a layman before the training but now I can say am a professional plumber.",position:"Plumber",authorImage:junaid,rating:"3.0"},
            ]
        }
    },
    methods:{
        sliderTestmonial(){
            var edule = new Swiper('.testimonial-active .swiper-container', {
                speed: 600,
                spaceBetween: 30,        
                pagination: {
                    el: '.testimonial-active .swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },  
                    768: {
                        slidesPerView: 1,
                    },            
                    992: {
                        slidesPerView: 2,
                    }
                },
            });

        }
    },
    mounted(){
        this.sliderTestmonial();
    }
}
</script>