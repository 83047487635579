<template>
  <swiper
    :slides-per-view="1"
    :space-between="50"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
    :pagination='{
       type: "bullets"
    }' 
    :autoplay='{
      delay: 60000
    }'
    :loop="true"
    :modules="[EffectFade]" effect="fade"
    :navigation="true"
  >
    <swiper-slide v-for="item in Slides" :key="item">
      <div class="position-relative w-100 d-flex justify-content-center">
        <img :src="item.image"/>
        <div class="position-absolute top-50 container-md mx-auto slider-caption">
          <p class="h1 text-secondary" v-html="item.bigTitle"></p>
          <!-- <p class="mt-2 text-secondary" >{{ item.smallTitle }}</p> -->
          <router-link :to="{name:'Register'}" class="btn btn-secondary text-primary">Sign Up</router-link>
        </div>
      </div>
    </swiper-slide>   
  </swiper>
</template>
<script>
  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';;

  // Import Swiper styles
   import 'swiper/swiper.min.css';
   import "swiper/components/pagination/pagination.min.css"
   import "swiper/components/navigation/navigation.min.css"
   import s1 from "@/assets/images/slider/slider1.jpg"
   import s2 from "@/assets/images/slider/slider2.jpg"
   import s3 from "@/assets/images/slider/slider3.jpg"

    import SwiperCore, {
    Pagination,Navigation, Autoplay
    } from 'swiper/core';
    // install Swiper modules
    SwiperCore.use([Pagination,Navigation, Autoplay, ]);

  export default {
    components: {
      Swiper,
      SwiperSlide,
    },
    data(){
      return{
          Slides:[
             { bigTitle:"Learn a New Skill and Stand Out", smallTitle:"Our instructors have pre-recoded excellent courses for you to make headway!", image:s2},
             { bigTitle:"Learn a New Skill Extensively<br> from Builders Hub Vocational Academy", smallTitle:"Our instructors have pre-recoded excellent courses for you to make headway!", image:s1},
             { bigTitle:"Learn a New Skill Extensively<br> from Builders Hub Vocational Academy", smallTitle:"Our instructors have pre-recoded excellent courses for you to make headway!", image:s3},
            //  { bigTitle:"Learn a new skill extensively<br> from Builders Hub Vocational Academy", smallTitle:"Our instructors have pre-recoded excellent courses for you to make headway!", image:"https://images.pexels.com/photos/1216589/pexels-photo-1216589.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=500&w=940"},
            //  { bigTitle:"Learn a new skill extensively<br> from Builders Hub Vocational Academy", smallTitle:"Our instructors have pre-recoded excellent courses for you to make headway!", image:"https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=500&w=940"},
            //  { bigTitle:"Learn a new skill extensively<br> from Builders Hub Vocational Academy", smallTitle:"Our instructors have pre-recoded excellent courses for you to make headway!", image:"https://images.pexels.com/photos/2898199/pexels-photo-2898199.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=500&w=940"}

          ]
      }
    },
    methods:{
      onSwiper(swiper){
        //console.log(swiper);
      },
      onSlideChange(){
        //console.log('slide change');
      }
    },
  };
</script>
<style scoped>
.swiper-button-next, .swiper-button-prev{
    color: white !important;
}
.swiper-pagination-bullet-active{
  background: #000080 !important;
}

.slider-caption {
  background:rgba(0, 0, 120, .8);
  padding: 15px;
  border-radius: 12px;
  /* backdrop-filter: blur(16px) saturate(180%); */
  /* -webkit-backdrop-filter: blur(16px) saturate(180%);   */
}

</style>
