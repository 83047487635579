<template>
<!-- Header Section Start -->
        <div class="header-section">

            <!-- Header Top Start -->
            <div class="header-top d-none d-lg-block">
                <div class="container">

                    <!-- Header Top Wrapper Start -->
                    <div class="header-top-wrapper">

                        <!-- Header Top Left Start -->
                        <div class="header-top-left">
                            <p>Enroll and get 30% OFF</p> 
                        </div>
                        <!-- Header Top Left End -->

                        <!-- Header Top Medal Start -->
                        <div class="header-top-medal">
                            <div class="top-info">
                                <p><i class="flaticon-phone-call"></i> <a href="tel:(+234) 09073016297">+234 907 3016 305, (+234) 09073016297</a></p>
                                <p><i class="flaticon-email"></i> <a href="javascript:;">info@bhubcollege.com</a></p>
                            </div>
                        </div>
                        <!-- Header Top Medal End -->

                        <!-- Header Top Right Start -->
                        <div class="header-top-right">
                            <ul class="social">
                                <li><a href="https://facebook.com/bhubcollege"><i class="flaticon-facebook"></i></a></li>
                                <li><a href="https://twitter.com/bhubcollege"><i class="flaticon-twitter"></i></a></li>
                                <li><a href="https://instagram.com/bhubcollege"><i class="flaticon-instagram"></i></a></li>
                            </ul>
                        </div>
                        <!-- Header Top Right End -->

                    </div>
                    <!-- Header Top Wrapper End -->

                </div>
            </div>
            <!-- Header Top End -->

            <!-- Header Main Start -->
            <div ref="header" class="header-main">
                <div class="container">

                    <!-- Header Main Start -->
                    <div  class="header-main-wrapper">

                        <!-- Header Logo Start -->
                        <div class="header-logo">
                            <router-link :to="{name:'Home'}"><img src="@/assets/images/logo.png" width="100" alt="Logo"></router-link>
                        </div>
                        <!-- Header Logo End -->

                        <!-- Header Menu Start -->
                        <div class="header-menu d-none d-lg-block">
                            <ul class="nav-menu">
                                <li><router-link :class="{'text-dark': this.$route.name != 'Home'}" :to="{name:'Home'}">Home</router-link></li>
                                <li>
                                    <a :class="{'text-dark': this.$route.name != 'Home'}" href="javascript:;">About</a>
                                    <ul class="sub-menu">
                                        <li><router-link :to='{name:"About"}'>About  Builders' Hub Vocational Academy</router-link></li>
                                        <li><router-link :to="{ name:'UserCourses' }">Alumni</router-link></li>
                                    </ul>
                                </li>
                                <li v-if="getUser">
                                    <a href="#" :class="{'text-dark': this.$route.name != 'Home'}">Courses</a>
                                    <ul class="sub-menu">
                                        <li><router-link :to='{name:"Courses"}'>Courses</router-link></li>
                                        <!-- <li><router-link :to="{ name:'UserCourses' }">My Courses</router-link></li> -->
                                    </ul>
                                </li>
                                <li v-else>
                                    <router-link :class="{'text-dark': this.$route.name != 'Home'}" :to='{name:"Courses"}'>Courses</router-link>
                                </li>
                                <!-- <li><router-link :to='{name:"Events"}'>Events</router-link></li> -->
                                <li><router-link :class="{'text-dark': this.$route.name != 'Home'}"  :to="{ name:'Events'}">Events</router-link></li>
                                <li>
                                    <a href="#" :class="{'text-dark': this.$route.name != 'Home'}" >Careers</a>
                                    <ul class="sub-menu">
                                        <!-- <li><router-link :class="{'text-dark': this.$route.name != 'Home'}" :to='{name:"Courses"}'>Recruitment</router-link></li> -->
                                        <li><router-link :class="{'text-dark': this.$route.name != 'Home'}" :to="{ name:'UserCourses' }">Vacancies</router-link></li>
                                    </ul>
                                </li>
                                <!-- <li><router-link :class="{'text-dark': this.$route.name != 'Home'}" :to="{ name:'Blog'}" >Blog</router-link></li> -->
                                <li><router-link :class="{'text-dark': this.$route.name != 'Home'}"  :to="{ name:'Partnership'}">Partnership</router-link></li>
                                <li><router-link :class="{'text-dark': this.$route.name != 'Home'}"  :to="{ name:'Contact'}">Contact</router-link></li>
                            </ul>

                        </div>
                        <!-- Header Menu End -->

                        <!-- Header Sing In & Up Start -->
                        <div class="header-sign-in-up d-none d-lg-block">
                            <ul v-if="getUser">
                                <li><router-link :class="{'text-primary': this.$route.name == 'Home'}" :to="{name:'Login'}" class="sign-in">Sign In</router-link></li>
                                <li><router-link :to="{name:'Register'}" class="sign-up bg-primary text-white">Sign Up</router-link></li>
                            </ul>
                        </div>
                        <!-- Header Sing In & Up End -->

                        <!-- Header Mobile Toggle Start -->
                        <div class="header-toggle d-lg-none">
                            <a class="menu-toggle" @click="toogleMobileNav" href="javascript:void(0)">
                                <span></span>
                                <span></span>
                                <span></span>
                            </a>
                        </div>
                        <!-- Header Mobile Toggle End -->

                    </div>
                    <!-- Header Main End -->

                </div>
            </div>
            <!-- Header Main End -->

        </div>
        <!-- Header Section End -->
        <MobileNav v-if="showNav" :getUser="getUser" @closeNav="toogleMobileNav" />
</template>
<script>
import MobileNav from '@/components/MobileNav.vue'
export default{
    name:"Header",
    components:{
        MobileNav
    },
    data(){
        return{
            showNav:false
        }
    },
    computed:{
        getUser(){
            return this.$store.getters.getUser
        }
    },
    methods:{
        toogleMobileNav(){
            this.showNav = !this.showNav;
        },
        headerSticky(){
            let scroll = window.scrollY;
            let menu = document.querySelector('.header-main');
            let whiteTexts = document.querySelectorAll('.nav-menu > li > a');
            let sign = document.querySelector('.sign-in');
            if (scroll <= 100) {
                menu.classList.remove("sticky");
                if(this.$route.name == 'Home'){
                     sign.classList.add("text-secondary");
                    whiteTexts.forEach((item) => {
                        item.classList.remove("text-dark");
                    })
                }          
            }else{
                sign.classList.remove("text-secondary");
                if(this.$route.name == 'Home'){
                    whiteTexts.forEach((item) => {
                        item.classList.add("text-dark");
                    })
                }
                menu.classList.add("sticky");
            }
        }
    },
    created(){
      window.addEventListener('scroll', this.headerSticky);
    }
}
</script>
<style scoped>
    ul li a {
        /* font-size: 24px; */
    }
    ul li {
        padding: 15px !important;
    }
</style>