<template>
<!-- Brand Logo Start -->
<div class="section section-padding-02 mb-8">

    <div class="container">

        <!-- Brand Logo Wrapper Start -->
        <div class="brand-logo-wrapper">
            <!-- Section Title Start -->
            <div class="section-title shape-03">
                <h2 class="main-title">Our partners at<span>  Builders' Hub Vocational Academy</span></h2>
            </div>
            <!-- Section Title End -->

            <!-- Brand Logo Start -->
            <div class="brand-logo brand-active">
                <div class="swiper-container">
                    <div class="swiper-wrapper">

                        <!-- Single Brand Start -->
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/woodoc.jpg" alt="woodoc">
                        </div>
                        <!-- Single Brand End -->

                        <!-- Single Brand Start -->
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/solar.png" alt="solar">
                        </div>
                        <!-- Single Brand End -->

                        <!-- Single Brand Start -->
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/schneider.png" alt="schneider">
                        </div>
                        <!-- Single Brand End -->

                        <!-- Single Brand Start -->
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/jaq.png" alt="jaquar">
                        </div>

                        <!-- Single Brand Start -->
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/undp.jpg" alt="undp">
                        </div>
                        <!-- Single Brand End -->

                        <!-- Single Brand Start -->
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/itf.png" alt="itf">
                        </div>
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/labour.png" alt="itf" width="100">
                        </div>
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/nabteb.png" alt="itf" width="100">
                        </div>
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/bacwwan.png" alt="itf">
                        </div>
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/tolaram.png" alt="tolaram">
                        </div>
                        <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/lsetf.png" alt="tolaram">
                        </div>

                        <!-- Single Brand Start -->
                        <!-- <div class="single-brand swiper-slide">
                            <img src="@/assets/images/brand/bacwwan.png" alt="bacwwan">
                        </div> -->
                        <!-- Single Brand End -->

                    </div>
                </div>
            </div>
            <!-- Brand Logo End -->

        </div>
        <!-- Brand Logo Wrapper End -->

    </div>
</div>
<!-- Brand Logo End -->
</template>
<script>
import Swiper from '@/assets/js/plugins/swiper-bundle.min.js';
export default{
    name:"Brand",
    methods:{
      sliderBrand(){
        var edule = new Swiper('.brand-active .swiper-container', {
          speed: 600,
          spaceBetween: 30,
          loop: true,
          breakpoints: {
              0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
              },  
              576: {
                  slidesPerView: 3,
              },  
              768: {
                  slidesPerView: 4,
              },            
              992: {
                  slidesPerView: 5,
                  spaceBetween: 45,
              },            
              1200: {
                  slidesPerView: 5,
                  spaceBetween: 85,
              }
          },
          autoplay: {
              delay: 2000,
          },
        });

      },
    },
    mounted(){
        this.sliderBrand();
    }
}
</script>