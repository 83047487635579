<template>
<!-- Mobile Menu Start -->
    <div ref="navcontent" class="mobile-menu">

        <!-- Menu Close Start -->
        <a class="menu-close" @click="closeNav">
            <i class="icofont-close-line"></i>
        </a>
        <!-- Menu Close End -->

        <!-- Mobile Top Medal Start -->
        <div class="mobile-top">
            <p><i class="flaticon-phone-call"></i> <a href="tel:(+234) 09073016297">(+234) 09073016297</a></p>
            <p><i class="flaticon-email"></i> <a href="javascript:;">info@bhubcollege.com</a></p>
        </div>
        <!-- Mobile Top Medal End -->

        <!-- Mobile Sing In & Up Start -->
        <div class="mobile-sign-in-up">
            <ul>
                <li><router-link :to='{name:"Login"}' class="sign-in" >Sign In</router-link></li>
                <li><router-link :to='{name:"Register"}' class="sign-up" >Sign Up</router-link ></li>
            </ul>
        </div>
        <!-- Mobile Sing In & Up End -->

        <!-- Mobile Menu Start -->
        <div class="mobile-menu-items">
            <ul class="nav-menu">
                <li><router-link :to='{name:"Home"}'>Home</router-link></li>
                <li><router-link :to='{name:"About"}'>About</router-link></li>
                <li v-if="getUser"  @click="expandSubMenu" class="menu-item-has-children">
                    <a href="#">All Courses</a>
                    <span class="mobile-menu-expand"></span>
                    <MobileNavDropdown v-if="showSubMenu" :sublinks="subPageslinks"/>
                </li>
                <li v-else>
                    <router-link :to='{name:"Courses"}'>All Courses</router-link>
                </li>
                <!-- <li>
                    <router-link :to='{name:"Blog"}'>Blog</router-link>
                </li> -->
                <li><router-link :to='{name:"Events"}'>Events</router-link></li>
                <li><router-link :to='{name:"Home"}'>Careers</router-link></li>
                <li><router-link :to='{name:"Contact"}'>Contact</router-link></li>
            </ul>

        </div>
        <!-- Mobile Menu End -->

        <!-- Mobile Menu End -->
        <div class="mobile-social">
            <ul class="social">
                <li><a href="#"><i class="flaticon-facebook"></i></a></li>
                <li><a href="#"><i class="flaticon-twitter"></i></a></li>
                <li><a href="#"><i class="flaticon-skype"></i></a></li>
                <li><a href="#"><i class="flaticon-instagram"></i></a></li>
            </ul>
        </div>
        <!-- Mobile Menu End -->

    </div>
    <!-- Mobile Menu End -->

    <!-- Overlay Start -->
    <div ref="overlay" @click.self="closeNav"  class="overlay"></div>
    <!-- Overlay End -->
</template>
<script>
import MobileNavDropdown from '@/components/MobileNavDropdown';
export default{
    name:"MobileNav",
    components:{
        MobileNavDropdown
    },
    props:['getUser'],
    data(){
        return{
            showSubMenu:false,
            subPageslinks:[
                {routeName:'Courses',name:"Courses"},
                {routeName:'UserCourses',name:"My Courses"}
            ]
        }
    },
    emits: ["closeNav"],
    methods:{
        closeNav(){
            this.$refs.navcontent.classList.remove("open");
            this.$refs.overlay.classList.remove("open");
             setTimeout(()=>{
               this.$emit('closeNav') 
            },2000)
        },
        expandSubMenu(event){
            this.showSubMenu = !this.showSubMenu
             let a = event.target;
            let li = a.parentNode;
            if(this.showSubMenu){
                li.classList.add("active-expand");
            }else{
                li.classList.remove("active-expand");
            }
        }
    },
    mounted(){
        setTimeout(()=>{
            this.$refs.overlay.classList.add("open");
            this.$refs.navcontent.classList.add("open");
        },100)
        
    }
}
</script>