import { createStore } from 'vuex'

export default createStore({
  state: {
    user:"Ajayi",
    Categories: [
      // { id: 1, name: "Plumbing" },
      // { id: 2, name: "Electrical " },
      // { id: 3, name: "Painting" },
      // { id: 4, name: "Carpentry" },
      // { id: 5, name: "Interior Design" },
      // { id: 5, name: "Masonry" },
      {id: 1, name: "Electrical Installation "},
      {id: 2, name: "Plumbing"},
      {id: 3, name: "Carpentry/Furniture Making"},
      {id: 4, name: "Tiling"},
      {id: 5, name: "Masonry"},
      {id: 6, name: "Interior Design (Basic)"},
      {id: 6, name: "Interior Design (Masterclass)"},
      // {id: 8, name: "Building construction & Supervision"},
      {id: 9, name: "HVAC"},
      {id: 10, name: "Welding & Fabrication"},
      {id: 11, name: "Solar Design & Installation"},
      {id: 12, name: "Painting"},
      {id: 13, name: "Facility Maintenance"},
      {id: 14, name: "MEP"},
    ]
  },
  getters:{
      getUser(state){
        return state.user
      }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
