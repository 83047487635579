<template>
<!-- Single Courses Start -->
<div class="single-courses">
    <div class="courses-images">
        <router-link :to='{name: "CourseDetail", params:{ slug:course.name, course:JSON.stringify(course) } }'><img :src="course.image" alt="Courses"></router-link>
    </div>
    <div class="courses-content">
        <div class="courses-author">
            <div class="author">
                <div class="author-thumb">
                    <a href="#"><img :src="course.authorImage" alt="Author"></a>
                </div>
                <div class="author-name">
                    <a class="name" href="#">{{ course.authorName }}</a>
                </div>
            </div>
            <div class="tag">
                <a href="#">{{ course.category }}</a>
            </div>
        </div>

        <h4 class="title"><router-link :to='{name: "CourseDetail", params:{ slug:course.name, course:JSON.stringify(course) } }' :course="course" >{{ course.name }}</router-link ></h4>
        <div class="courses-meta">
            <span> <i class="icofont-clock-time"></i>{{ course.time }} </span>
            <span> <i class="icofont-read-book"></i> {{ course.lectures }} Lectures </span>
        </div>
        <div class="courses-price-review">
            <div v-if="course.regularPrice && course.price" class="courses-price">
                <span class="sale-parice">₦ {{ course.price  }}</span>
                <span class="old-parice">₦ {{ course.regularPrice  }}</span>
            </div>
            <div v-else-if="course.price" class="courses-price">
                <span class="sale-parice">₦{{ course.price  }}</span>
            </div>
            <div v-else class="courses-price">
                <span class="sale-parice">Free</span>
                </div>
            <div class="courses-review">
                <span class="rating-count">{{ course.rating }}</span>
                <span class="rating-star">
                    <span ref="ratingStar" class="rating-bar"></span>
                </span>
            </div>
        </div>
    </div>
</div>
<!-- Single Courses End -->
</template>
<script>
export default{
    name:"SingleCourseCard",
    props:['course'],
    mounted(){
        let width = (this.course.rating/5.0) * 100 
        this.$refs.ratingStar.style.width = width + '%' 
    }
}
</script>