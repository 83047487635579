<template>
  <div>
    <router-view />
  </div>
</template>
<script>
 export default{
   name:"App",
   computed:{
      getUser(){
        return this.$store.getters.getUser
      }
   }
 }
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
