<template>
 <ul ref="subMenu" class="sub-menu">
    <li v-for="sublink in sublinks" :key="sublink.id"><router-link :to="{name : sublink.routeName}">{{ sublink.name }}</router-link></li>
</ul>
</template>
<script>
export default{
    name:"MobileNavDropdown",
    props:['sublinks'],
    methods:{
        sideDown(){
            this.$refs.subMenu.style.transition = 'height 0.5s ease-out';
            this.$refs.subMenu.style.overflow = "hidden";
            this.$refs.subMenu.style.height ='0' 
            this.$refs.subMenu.style.height = `${this.$refs.subMenu.scrollHeight}px`;
        }
    },
    mounted(){
        this.sideDown();
    },
    updated(){
        this.$refs.subMenu.style.height = `0`;
        this.$refs.subMenu.style.height = `${this.$refs.subMenu.scrollHeight}px`;
    }
}
</script>