<template>
<!-- Single Testimonial Start -->
<div class="single-testimonial swiper-slide">
    <div class="testimonial-author">
        <div class="author-thumb">
            <img :src="testimony.authorImage" alt="Author" width="100">

            <i class="icofont-quote-left"></i>
        </div>

        <span class="rating-star">
                <span class="rating-bar" ref="ratingStar"></span>
        </span>
    </div>
    <div class="testimonial-content">
        <p>{{ testimony.text }}</p>
        <h4 class="name">{{ testimony.name }}</h4>
        <span class="designation">{{ testimony.position }}</span>
    </div>
</div>
<!-- Single Testimonial End -->

</template>
<script>
export default{
    name:"singleTestmonial",
    props:['testimony'],
    mounted(){
        let width = (this.testimony.rating/5.0) * 100 
        this.$refs.ratingStar.style.width = width + '%' 
    }
}
</script>