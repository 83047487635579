<template>
<div class="main-wrapper">
  <Header/>
    <Slider/>
    <AllCourses/>
    <InstructorCTA/>
    <HowItWorks/>
    <CTA/>
    <Testimonal/>
    <Brand/>
    <Blog/>
  <Footer/>
</div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import CTA from '@/components/CTA.vue';
import Slider from '@/components/home/Slider1.vue';
import InstructorCTA from '@/components/home/InstructorsCTA.vue';
import HowItWorks from '@/components/home/HowItWorks.vue';
import Testimonal from '@/components/home/Testimonal.vue';
import AllCourses from '@/components/AllCourses.vue';
import Brand from '@/components/home/Brand.vue';
import Blog from '@/components/home/Blog.vue';
export default {
  name: 'Home',
  components: {
    Header,Footer,Slider,AllCourses,InstructorCTA,HowItWorks,CTA,Testimonal,Brand,Blog
  }
}
</script>
