<template>
    <!-- Call to Action Start -->
        <div class="section section-padding-02">
            <div class="container">

                <!-- Call to Action Wrapper Start -->
                <div class="call-to-action-wrapper">
                    <img class="cat-shape-02" src="@/assets/images/shape/shape-13.svg" alt="Shape">
                    <div class="row align-items-center">
                        <div class="col-md-6">

                            <!-- Section Title Start -->
                            <div class="section-title shape-02">
                                <h5 class="sub-title">Become An Instructor</h5>
                                <h2 class="main-title">Become an Instructor at Builders' Hub Vocational Academy</h2>
                            </div>
                            <!-- Section Title End -->

                        </div>
                        <div class="col-md-6">
                            <div class="call-to-action-btn">
                                <router-link :to="{name:'Register'}" class="btn btn-primary btn-hover-dark">Learn more</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Call to Action Wrapper End -->

            </div>
        </div>
        <!-- Call to Action End -->
</template>
<script>
export default{
    name:"Instructor"
}

</script>